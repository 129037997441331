"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IFRAME_COMMS_MESSAGE_TYPE = exports.DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY = void 0;

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
const IFRAME_COMMS_MESSAGE_TYPE = "__embedded_comms__";
exports.IFRAME_COMMS_MESSAGE_TYPE = IFRAME_COMMS_MESSAGE_TYPE;
const DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY = {
  visible: "show_filters",
  expanded: "expand_filters"
};
exports.DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY = DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY;