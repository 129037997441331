(function () {var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;enterModule && enterModule(module);})();var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {return a;};
























// Each message we send on the channel specifies an action we want the other side to cooperate with.
var Actions;(function (Actions) {Actions["GET"] = "get";Actions["REPLY"] = "reply";Actions["EMIT"] = "emit";Actions["ERROR"] = "error";})(Actions || (Actions = {}));





















function isGet(message) {
  return message.switchboardAction === Actions.GET;
}







function isReply(message) {
  return message.switchboardAction === Actions.REPLY;
}







function isEmit(message) {
  return message.switchboardAction === Actions.EMIT;
}







function isError(message) {
  return message.switchboardAction === Actions.ERROR;
}

/**
 * A utility for communications between an iframe and its parent, used by the Superset embedded SDK.
 * This builds useful patterns on top of the basic functionality offered by MessageChannel.
 *
 * Both windows instantiate a Switchboard, passing in their MessagePorts.
 * Calling methods on the switchboard causes messages to be sent through the channel.
 */
export class Switchboard {






  // used to make unique ids






  constructor(params) {this.port = void 0;this.name = '';this.methods = {};this.incrementor = 1;this.debugMode = void 0;this.isInitialised = void 0;
    if (!params) {
      return;
    }
    this.init(params);
  }

  init(params) {
    if (this.isInitialised) {
      this.logError('already initialized');
      return;
    }

    const { port, name = 'switchboard', debug = false } = params;

    this.port = port;
    this.name = name;
    this.debugMode = debug;

    port.addEventListener('message', async (event) => {
      this.log('message received', event);
      const message = event.data;
      if (isGet(message)) {
        // find the method, call it, and reply with the result
        this.port.postMessage(await this.getMethodResult(message));
      } else if (isEmit(message)) {
        const { method, args } = message;
        // Find the method and call it, but no result necessary.
        // Should this multicast to a set of listeners?
        // Maybe, but that requires writing a bunch more code
        // and I haven't found a need for it yet.
        const executor = this.methods[method];
        if (executor) {
          executor(args);
        }
      }
    });

    this.isInitialised = true;
  }

  async getMethodResult(_ref)



  {let { messageId, method, args } = _ref;
    const executor = this.methods[method];
    if (executor == null) {
      return {
        switchboardAction: Actions.ERROR,
        messageId,
        error: `[${this.name}] Method "${method}" is not defined` };

    }
    try {
      const result = await executor(args);
      return {
        switchboardAction: Actions.REPLY,
        messageId,
        result };

    } catch (err) {
      this.logError(err);
      return {
        switchboardAction: Actions.ERROR,
        messageId,
        error: `[${this.name}] Method "${method}" threw an error` };

    }
  }

  /**
   * Defines a method that can be "called" from the other side by sending an event.
   */
  defineMethod(methodName, executor) {
    this.methods[methodName] = executor;
  }

  /**
   * Calls a method registered on the other side, and returns the result.
   *
   * How this is accomplished:
   * This switchboard sends a "get" message over the channel describing which method to call with which arguments.
   * The other side's switchboard finds a method with that name, and calls it with the arguments.
   * It then packages up the returned value into a "reply" message, sending it back to us across the channel.
   * This switchboard has attached a listener on the channel, which will resolve with the result when a reply is detected.
   *
   * Instead of an arguments list, arguments are supplied as a map.
   *
   * @param method the name of the method to call
   * @param args arguments that will be supplied. Must be serializable, no functions or other nonense.
   * @returns whatever is returned from the method
   */
  get(method, args) {if (args === void 0) {args = undefined;}
    return new Promise((resolve, reject) => {
      if (!this.isInitialised) {
        reject(new Error('Switchboard not initialised'));
        return;
      }
      // In order to "call a method" on the other side of the port,
      // we will send a message with a unique id
      const messageId = this.getNewMessageId();
      // attach a new listener to our port, and remove it when we get a response
      const listener = (event) => {
        const message = event.data;
        if (message.messageId !== messageId) return;
        this.port.removeEventListener('message', listener);
        if (isReply(message)) {
          resolve(message.result);
        } else {
          const errStr = isError(message) ?
          message.error :
          'Unexpected response message';
          reject(new Error(errStr));
        }
      };
      this.port.addEventListener('message', listener);
      this.port.start();
      const message = {
        switchboardAction: Actions.GET,
        method,
        messageId,
        args };

      this.port.postMessage(message);
    });
  }

  /**
   * Emit calls a method on the other side just like get does.
   * But emit doesn't wait for a response, it just sends and forgets.
   *
   * @param method
   * @param args
   */
  emit(method, args) {if (args === void 0) {args = undefined;}
    if (!this.isInitialised) {
      this.logError('Switchboard not initialised');
      return;
    }
    const message = {
      switchboardAction: Actions.EMIT,
      method,
      args };

    this.port.postMessage(message);
  }

  start() {
    if (!this.isInitialised) {
      this.logError('Switchboard not initialised');
      return;
    }
    this.port.start();
  }

  log() {
    if (this.debugMode) {for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {args[_key] = arguments[_key];}
      console.debug(`[${this.name}]`, ...args);
    }
  }

  logError() {for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {args[_key2] = arguments[_key2];}
    console.error(`[${this.name}]`, ...args);
  }

  getNewMessageId() {
    // eslint-disable-next-line no-plusplus
    return `m_${this.name}_${this.incrementor++}`;
  } // @ts-ignore
  __reactstandin__regenerateByEval(key, code) {// @ts-ignore
    this[key] = eval(code);}}const _default =
new Switchboard();export default _default;;(function () {var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;if (!reactHotLoader) {return;}reactHotLoader.register(isGet, "isGet", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");reactHotLoader.register(isReply, "isReply", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");reactHotLoader.register(isEmit, "isEmit", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");reactHotLoader.register(isError, "isError", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");reactHotLoader.register(Switchboard, "Switchboard", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");reactHotLoader.register(_default, "default", "/Users/ville/apple/apache-superset/superset-frontend/packages/superset-ui-switchboard/src/switchboard.ts");})();;(function () {var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;leaveModule && leaveModule(module);})();